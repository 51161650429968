import React from 'react';

import { useLocation } from '@reach/router';

import Link from '@components/Link';
import Loading from '@components/Loading';
import Title from '@components/Title';

import ActiveRecurringDonations from './SubscriptionsList';

import { useFetchSiteCoreReport } from '@repositories/reports';

import { getLanguageFromURL } from '@utils/query-params';

import { useTranslation } from '@external/react-i18next';

import { SitecoreReportTypeEnum } from '@typings/sitecore';

const MyDonations: React.FC = () => {
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const searchString = 'donorhistoryreport';
  const { data, loading } = useFetchSiteCoreReport(
    SitecoreReportTypeEnum.Individual
  );
  const donorHistoryLink = React.useMemo(() => {
    if (loading || !data?.reportData) return '#';
    const linkData = data.reportData.flatMap(report =>
      report.children.flatMap(child =>
        child.subReports
          .filter(
            subReport =>
              subReport.reportTechnicalName.toLowerCase() === searchString
          )
          .map(subReport => ({
            oktaId: child.oktaId,
            dataSelector: child.dataSelector,
            reportTechnicalName: subReport.reportTechnicalName,
          }))
      )
    )[0];
    return linkData
      ? `/${getLanguageFromURL()}/club-and-district-reports/${
          linkData.oktaId
        }/${linkData.reportTechnicalName}/${
          linkData.dataSelector
        }?from=${encodeURIComponent(location.pathname)}`
      : '#';
  }, [data, loading, searchString]);

  const contributionFormHref =
    i18n.language.toLowerCase() === 'ja'
      ? `/document/piif-contribution-form-rotarians-clubs`
      : `/document/contribution-form-rotary-foundation`;
  const recognitionFormHref = `/document/paul-harris-fellow-recognition-transfer-request-form`;
  const indiaPanForm = '/my-donations/government-id/ind';
  const italyFiscalCodeForm = '/my-donations/government-id/ita';
  const koreaRRNForm = '/my-donations/government-id/kor';
  const links = [
    {
      title: t(
        'online-giving.my-donations-form.my-reports.donor-history-report',
        'DONOR HISTORY REPORT'
      ),
      to: donorHistoryLink,
      key: 'donor-history-report',
    },
    {
      title: t(
        'online-giving.my-donations-form.my-reports.individual-form',
        'INDIVIDUAL CONTRIBUTION FORM'
      ),
      to: contributionFormHref,
      key: 'individual-contribution-form',
    },
    {
      title: t(
        'online-giving.my-donations-form.my-reports.paul-harris-request',
        'PAUL HARRIS FELLOW RECOGNITION AND TRANSFER REQUEST'
      ),
      to: recognitionFormHref,
      key: 'paul-harris-fellow',
    },
  ];

  const ginAppLinks = [
    {
      title: t(
        'online-giving.my-donations-form.pan-page-link',
        'Add your Indian Permanent Account Number (PAN)'
      ),
      to: indiaPanForm,
      key: 'ind',
    },
    {
      title: t(
        'online-giving.my-donations-form.fiscal-code-page-link',
        'Add your Italian Fiscal Code'
      ),
      to: italyFiscalCodeForm,
      key: 'ita',
    },
    {
      title: t(
        'online-giving.my-donations-form.rrn-page-link',
        'Add your Korean Resident Registration Number (RRN)'
      ),
      to: koreaRRNForm,
      key: 'kor',
    },
  ];
  return (
    <div>
      <Title>
        {t('online-giving.my-donations-form.title', 'My Donations')}
      </Title>
      <p className="text-xs text-black mb-8 mt-20 mr-3">
        {t(
          'online-giving.my-donations-form.subheading',
          'Access your contribution history, commitments, and recognition achievements. You can also update your online Rotary Direct recurring gift arrangements.'
        )}
      </p>
      <div className="mb-6 font-medium text-lg-22 text-black">
        {t(
          'online-giving.my-donations-form.my-reports.subheading',
          'My Reports & Forms'
        )}
      </div>
      {links.map(link => (
        <div className="mb-4" key={link.key}>
          {link.key === 'donor-history-report' && !donorHistoryLink ? (
            <Loading small inline />
          ) : (
            <Link
              className="link-styles-off text-bright-blue-600 text-xs font-bold active:text-bright-blue-600"
              to={link.to}
              target="_blank"
              useExternalLink
            >
              {link.title}
            </Link>
          )}
        </div>
      ))}
      <hr className="mb-5 mt-5 border-t-2 border-b border-gray-300" />
      <div className="mb-6 font-medium text-lg-22 text-black">
        {t(
          'online-giving.my-donations-form.my-reports.gin-app-heading',
          'My Government Identification Number (India, Italy & Korea Only)'
        )}
      </div>
      <div className="mb-4">
        {ginAppLinks.map(link => (
          <div className="mb-4" key={link.key}>
            <Link
              className="link-styles-off text-bright-blue-600 text-xs font-bold active:text-bright-blue-600"
              to={link.to}
              target="_blank"
              useExternalLink
            >
              {link.title}
            </Link>
          </div>
        ))}
      </div>
      <hr className="mb-7 mt-9 border-t-2 border-b border-gray-300" />
      <div className="mb-2 font-bold text-lg-22 text-black">
        {t(
          'online-giving.my-donations-form.active-recurring-donations-heading',
          'Active recurring donations'
        )}
        <hr className="mb-7 mt-2 border-t-2 border-b border-gray-300" />
      </div>
      <ActiveRecurringDonations />
    </div>
  );
};

export default MyDonations;
