import React, { useEffect, useRef, useState } from 'react';

import Icon from '@components/Icon';

import './SubscriptionsResult/SubscriptionResultStyles.css';

import { editSubscriptionRecord } from '@domain/my-donations/constants';

import getDonateWidgetMode from '@utils/getDonateWidgetMode';

import { useTranslation } from '@external/react-i18next';

import { LoadReactApp } from '@typings/widget';

interface EditSubscriptionModalProps {
  onCloseEditModal: () => void;
  subscriptionEditRecord: editSubscriptionRecord;
}
const EditSubscriptionModal: React.FC<EditSubscriptionModalProps> = ({
  onCloseEditModal,
  subscriptionEditRecord,
}) => {
  const modalContainerRef = useRef<HTMLDivElement | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const donateWidgetRef = useRef<HTMLDivElement | null>(null);
  const scriptRef = useRef<HTMLScriptElement | null>(null);
  const [showInfoDiv, setShowInfoDiv] = useState(false);
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(window.location.search);
  const transactionId = searchParams.get('epp_transaction_id');
  const rnwView = searchParams.get('rnw-view');
  const donateWidgetLoadBuildJS = process.env
    .GATSBY_DONATE_WIDGET_LOAD_BUILD_JS as string;

  const doanteWidgetURL = process.env.GATSBY_DONATE_WIDGET_URL as string;

  useEffect(() => {
    if (transactionId && rnwView) {
      setShowInfoDiv(true);
    }

    if (
      !donateWidgetRef.current ||
      !modalContainerRef.current ||
      !modalRef.current
    )
      return;

    if (document.getElementById('dynamic-content-script')) return;

    const script = document.createElement('script');
    script.id = 'dynamic-content-script';
    script.setAttribute('data-testid', 'content-script-dynamic');
    script.type = 'text/javascript';
    script.src = donateWidgetLoadBuildJS;

    script.onload = () => {
      const data = subscriptionEditRecord;
      const gatsbyLanguage = window.__gatsby_language;

      const donateWidgetObject = {
        getLanguage: gatsbyLanguage,
        mode: getDonateWidgetMode('self-service'),
        subscription: {
          ...data,
        },
      };

      const donateWidget = () =>
        (window.loadReactApp as LoadReactApp)(
          doanteWidgetURL,
          donateWidgetObject,
          donateWidgetRef.current
        );

      const timeoutId = setTimeout(() => {
        donateWidget();
      }, 1000);
      return () => {
        clearTimeout(timeoutId);
      };
    };
    modalRef.current.appendChild(script);
    document.body.style.overflow = 'hidden';

    // eslint-disable-next-line consistent-return
    return () => {
      if (scriptRef.current) {
        scriptRef.current.remove();
        scriptRef.current = null;
      }
      document.body.style.overflow = '';
    };
  }, [subscriptionEditRecord, showInfoDiv]);

  return (
    <div
      className="modalEdit desktop:pl-8 desktop-8 mobile:pl-0 mobile:pr-0"
      ref={modalRef}
      data-testid="modal-edit"
    >
      <div className="modalContainer" ref={modalContainerRef}>
        {showInfoDiv && (
          <div className="text-lg leading-6 font-bold py-5 px-5 flex justify-between text-green-600 bg-green-100">
            <p>
              {t(
                'edit-subscription-modal-info',
                "Changes to your recurring donation are being processed. When processing is finished, you'll receive a confirmation email. You will then be able to see your changes reflected here."
              )}
            </p>
          </div>
        )}
        <div className="closeContainer">
          <div className="close-wrapper" onClick={onCloseEditModal}>
            <span className="close-text">{t('edit-modal-close', 'Close')}</span>
            <Icon
              className="close-button"
              name="ui/crossclose"
              size="20"
              color="bright-blue-600"
            />
          </div>
        </div>
        <div id="donate-widget" ref={donateWidgetRef} />
        <div className="closeContainer">
          <div className="close-wrapper" onClick={onCloseEditModal}>
            <span className="close-text">{t('edit-modal-close', 'Close')}</span>
            <Icon
              className="close-button"
              name="ui/crossclose"
              size="20"
              color="bright-blue-600"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSubscriptionModal;
