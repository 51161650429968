/* eslint-disable camelcase */
export type CreditCardCode =
  | 'es'
  | 'ezs'
  | 'sms'
  | 'pfc'
  | 'pef'
  | 'vis'
  | 'eca'
  | 'amx'
  | 'din'
  | 'dd'
  | 'pex'
  | 'pp'
  | 'twi'
  | 'dib'
  | 'eps'
  | 'elv';

export const months: Record<string, string> = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export type FilteredTitle = {
  fundTitle: string | null;
  fundId: number | null;
};

export type Subscriptions = {
  SubWidgetData?: SubWidgetData;
  SubscriptionId: string;
  FundType: string;
  SubFundType: string;
  FundName: string;
  SubFundName: string | null;
  Frequency: string;
  TranslatedFrequency: string;
  Amount: number | string;
  Currency: string;
  Status: string;
  NextCharged: string;
  MerchantConfig: string;
  WidgetID: string;
  EmailAddress: string;
  PhoneNumber: string;
  SubscriptionCreatedBy: string;
  SubscriptionCreationDate: string;
  SubscriptionCreationSource: string;
  NextChargeTimestamp: string;
  SubscriptionLastModificationDate: string;
  Payment: PaymentDetails;
  Individual: IndividualDetails;
  Address: AddressDetails;
  RequestSource: string;
};

export type SubWidgetData = {
  customerToken: string;
  fund: fund;
  country: country;
  currency: currency;
  user: userData;
};

export type PaymentDetails = {
  PaymentType: string;
  MaskedCC?: string | null;
  ExpirationMonth?: string | null;
  ExpirationYear?: string | null;
};

export type IndividualDetails = {
  MemberToken: string;
  FirstName: string;
  LastName: string;
  LocalizedName: string;
};

export type AddressDetails = {
  AddressLine1: string;
  AddressLine2?: string;
  AddressLine3?: string;
  City?: string;
  State?: string;
  InternationalProvince?: string;
  PostalCode?: string;
  Country: string;
};

export interface Countries {
  id: string;
  iso2: string;
  name?: CountryTNames;
}

export type CountryTNames = {
  de: string;
  en: string;
  es: string;
  fr: string;
  it: string;
  ja: string;
  ko: string;
  pt: string;
  default: string;
};

export interface Currencies {
  id: string;
  code: string;
  name: CurrencyTNames;
}

export type CurrencyTNames = {
  de: string;
  en: string;
  es: string;
  fr: string;
  it: string;
  ja: string;
  ko: string;
  pt: string;
};

export interface Fund {
  id: number;
  fundId: string;
  fundSubId: string;
  isPaymentType?: boolean;
  recurring?: boolean;
  typeName?: string;
  alias?: string;
  rnwPurposeText?: string;
  fundTitle?: {
    en?: string;
    fr?: string;
    de?: string;
    es?: string;
    it?: string;
    ja?: string;
    ko?: string;
    pt?: string;
  };
  fundSubtitle?: {
    en?: string;
    fr?: string;
    de?: string;
    es?: string;
    it?: string;
    ja?: string;
    ko?: string;
    pt?: string;
  };
}

export type SubscriptionsResultProps = {
  SubscriptionId: string;
  SubFundName?: string | null;
  TranslatedFrequency?: string | null;
  Amount?: number | string;
  Frequency?: string | null;
  NextChargeTimestamp?: string | null;
  Name?: string | null;
  PhoneNumber?: string | null;
  EmailAddress?: string | null;
  Address: AddressDetails;
  Payment: PaymentDetails;
  Currency?: string | null;
  MerchantConfig: string;
  removeSubscription: () => void;
  WidgetData?: SubWidgetData;
  RequestSource: string;
  isEditModalOpen: boolean;
  setIsEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleEditModalOpen: (data: editSubscriptionRecord) => void;
};

type country = {
  id: string;
};

type fund = {
  id: number;
};

type currency = {
  id: string;
};

export type editSubscriptionRecord = {
  subscriptionEdit: boolean;
  subscriptionData?: {
    subscriptionId: string;
    customerToken: string | undefined;
    fund: fund | undefined;
    country: country | undefined;
    currency: currency | undefined;
    frequencyInterval: string | null | undefined;
    amount: number | string;
    selectedRequestSource: string;
    user?: userData;
  };
};

type userData = {
  authenticated: boolean;
  sec_groups: SecGroupOutput[] | undefined;
  member_id: string | null | undefined;
  first_name: string | undefined;
  last_name: string | undefined;
  localized_name: string | undefined;
  phone: string | undefined;
  email: string | undefined;
  address: {
    line1: string | undefined;
    line2?: string | undefined;
    line3?: string | undefined;
    city?: string | undefined;
    state?: string | undefined;
    zip?: string | undefined;
    intl_province?: string | undefined;
    country: string | undefined;
  };
};

export type IndividualOrganization = {
  Name?: string;
  Id?: number;
  Type?: string;
  Key?: string;
};

export type IndividualSecurityGroupsResult = {
  Name?: string;
  TermAccessStatus?: string;
  Roles?: {
    Name?: string;
    StartDate?: string;
    IsDelegated?: boolean;
    RoleEventKey?: string;
    IsUnverified?: boolean;
    Key?: string;
  }[];
  Organizations: IndividualOrganization[];
  Key?: string;
};

export type SecGroupOutput = {
  sec_group_name: string;
  is_current_term: boolean;
  clubs: {
    club_name: string;
    club_id: number;
    club_type: string;
  }[];
};
